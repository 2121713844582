<template>
  <div class="club-page">
    <div class="header" ref="header">
      <div :class="`header-item ${scrollActive==='introduce'?'active':''}`" @click="scrollTo('introduce')">权益总览
      </div>
      <div :class="`header-item ${scrollActive==='right'?'active':''}`">
        <v-select :option="[
          {text:'包含VIP3大权益',value:'right1',className:'club-page-popover-action'},
          {text:'专享社群4大权益',value:'right2',className:'club-page-popover-action'},
          {text:'畅享教程2大权益',value:'right3',className:'club-page-popover-action'},
          // {text:'核心权益四',value:'right4'},
          // {text:'加餐权益',value:'rightExtra'},
        ]" @change="val=>scrollTo(val.value)" @toggle="val => rightsVisible = val" useSlot>
          <div class="rights-title" :class="rightsVisible?'rights-visible':''">
            核心权益
            <van-icon name="arrow-down"/>
          </div>
        </v-select>
      </div>
      <div :class="`header-item ${scrollActive==='comment'?'active':''}`" @click="scrollTo('comment')">俱乐部日常</div>
      <div :class="`header-item ${scrollActive==='server'?'active':''}`" @click="scrollTo('server')">真实评价</div>
    </div>
    <div class="body">
      <!--      <img class="club-img" id="introduce" src="https://cdn.001ppt.cn/h5/img/club/1.png">-->
      <!--      <img class="club-img" src="https://cdn.001ppt.cn/h5/img/club/3.png">-->
      <!--      <img class="club-img" src="https://cdn.001ppt.cn/h5/img/club/2.png">-->
      <!--      <img class="club-img" src="https://cdn.001ppt.cn/h5/img/club/4.png">-->
      <!--      <img class="club-img" id="right" src="https://cdn.001ppt.cn/h5/img/club/5.png">-->
      <!--      <img class="club-img" id="right1" src="https://cdn.001ppt.cn/h5/img/club/6.png">-->
      <!--      <img class="club-img" id="right2" src="https://cdn.001ppt.cn/h5/img/club/7.png">-->
      <!--      <img class="club-img" id="right3" src="https://cdn.001ppt.cn/h5/img/club/8.png">-->
      <!--      <img class="club-img" id="right4" src="https://cdn.001ppt.cn/h5/img/club/9.png">-->
      <!--      <img class="club-img" id="rightExtra" src="https://cdn.001ppt.cn/h5/img/club/10.png">-->
      <!--      <img class="club-img" id="comment" src="https://cdn.001ppt.cn/h5/img/club/11.png">-->
      <!--      <img class="club-img" src="https://cdn.001ppt.cn/h5/img/club/12.png">-->
      <!--      <img class="club-img" src="https://cdn.001ppt.cn/h5/img/club/13.png">-->
      <!--      <img class="club-img" id="server" src="https://cdn.001ppt.cn/h5/img/club/14.png">-->
      <!--      <img class="club-img" src="https://cdn.001ppt.cn/h5/img/club/15.png">-->
      <!--      <img class="club-img" src="https://cdn.001ppt.cn/h5/img/club/16.png">-->
      <!--      <img class="club-img" src="https://cdn.001ppt.cn/h5/img/club/17 无按钮.png">-->
      <img src="https://cdn.001ppt.cn/h5/img/club2/1.png" alt="" class="club-img">
      <img src="https://cdn.001ppt.cn/h5/img/club2/6.png" id="introduce" alt="" class="club-img">
      <img src="https://cdn.001ppt.cn/h5/img/club2/2.png" id="right" alt="" class="club-img">
      <img src="https://cdn.001ppt.cn/h5/img/club2/3.png" id="right1" alt="" class="club-img">
      <img src="https://cdn.001ppt.cn/h5/img/club2/4.png" id="right2" alt="" class="club-img">
      <img src="https://cdn.001ppt.cn/h5/img/club2/5.png" id="right3" alt="" class="club-img">
      <img src="https://cdn.001ppt.cn/h5/img/club2/7.png" id="comment" alt="" class="club-img">
      <img src="https://cdn.001ppt.cn/h5/img/club2/8.png" id="server" alt="" class="club-img">
      <img src="https://cdn.001ppt.cn/h5/img/club2/9.png" alt="" class="club-img">

      <div class="qa" @click="copy">
        <div>＊按钮无法点击？微信对话打开：m.001ppt.com/club</div>
        <div class="copy-btn">点击复制</div>
      </div>
    </div>


    <div class="footer">
      <!--      <div class="add-wx-btn" @click="showAddWxDialog">-->
      <!--        <div class="add-wx-btn-main">¥0报名了解更多(推荐)</div>-->
      <!--        <div class="add-wx-btn-sub">先领取新人方案包 \ 拿减¥100津贴</div>-->
      <!--        <div class="countdown">-->
      <!--          <div class="countdown-title">限时优惠 \ 今日剩余倒计时</div>-->
      <!--          <div class="countdown-time">{{ h }}h {{ m }}m {{ s }}s {{ ms }}</div>-->
      <!--        </div>-->
      <!--      </div>-->
      <router-link to="/member?id=5" class="pay-btn">
        <div class="pay-btn-main">
          <template v-if="loadings.loadVipPrice">
            <van-loading color="#fff" size="14" type="spinner"/>
          </template>
          <template v-else>我已了解清楚，￥{{ computedPrice.finalPrice }}马上入会</template>
        </div>
        <!--        <div class="pay-btn-main">{{ config.CLUB_PAGE_BOTTOM_BTN_TEXT }}</div>-->
        <!--        <div class="pay-btn-sub">{{ config.CLUB_PAGE_BOTTOM_BTN_SUB_TEXT }}</div>-->
      </router-link>
    </div>

    <club-add-wx-dialog ref="clubAddWxDialog"/>
    <club-pay-dialog ref="clubPayDialog" @success="onPaySuccess"/>
    <club-h5-pay-dialog ref="clubH5PayDialog" @success="onPaySuccess"/>
    <club-pay-success-dialog ref="clubPaySuccessDialog"/>
    <iframe style="height: 1px;width: 1px;visibility: hidden" ref="frame" src="" frameborder="0"></iframe>
  </div>
</template>


------------------ script ------------------
<script>
import {copyToClipBoard, getElementPos, sleep} from "../../config/util";
import ClubAddWxDialog from '../../components/club/clubAddWxDialog';
import vSelect from '@/components/select';
import ClubPayDialog from "../../components/club/clubPayDialog";
import ClubPaySuccessDialog from "../../components/club/clubPaySuccessDialog";
import ClubH5PayDialog from "../../components/club/clubH5PayDialog";
import {clubV2Api} from "../../api/v2/clubV2Api";
import {configV2Api as configApiV2} from "../../api/v2/configV2Api";
import {walletApiV2} from "../../api/v2/walletApiV2";
import {vipRightV2Api} from "../../api/v2/vipRightV2Api";
import {mapGetters} from "vuex";

export default {
  components: {ClubH5PayDialog, ClubPayDialog, vSelect, ClubAddWxDialog, ClubPaySuccessDialog},
  computed: {
    ...mapGetters(['userVip'])
  },
  data() {
    return {
      scrollActive: 'introduce',
      rightsVisible: false,
      h: 0,
      m: 0,
      s: 0,
      ms: 0,
      tops: {
        right: 0,
        comment: 0,
        server: 0
      },
      paySuccess: false,
      config: {
        CLUB_PAGE_BOTTOM_BTN_TEXT: '',
        CLUB_PAGE_BOTTOM_BTN_SUB_TEXT: ''
      },
      allVips: [],
      currVip: {
        id: '',
        packages: []
      },
      computedPrice: {
        price: '',
        finalPriceWithoutYanzhi: '',
        finalPrice: '',
        walletRemainYanzhi: ''
      },
      wallet: {
        totalRemainValue: 0
      },
      loadings: {
        loadVipPrice: true
      }
    }
  },

  async mounted() {
    this.$store.dispatch('getMemberInfo').then()
    this.loadVipPrice()

    this.loadConfig();
    this.initTops();
    this.startCountDown();
    window.addEventListener('scroll', this.onScroll);
    await this.loadHasPay();
    if (this.paySuccess) {
      this.showAddWxDialog();
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },


  methods: {

    async loadVipPrice() {
      try {
        await this.loadAllVips()
        this.switchVipRight(this.$route.query.id || '5', 0)
      } catch (e) {
        console.error(e)
      } finally {
        this.loadings.loadVipPrice = false
      }
    },

    switchVipRight(vipRightId, packageIndex) {
      packageIndex = packageIndex || 0
      vipRightId = vipRightId || '4'
      this.currVip = this.allVips.find(v => v.id === vipRightId)
      this.switchPackage(this.currVip.packages[packageIndex])
    },


    async loadAllVips() {
      const resProm = vipRightV2Api.findAllForPay2()
      await sleep(300)
      const res = await resProm
      const vipRights = res.vipRights
      for (const v of vipRights) {
        v.rights = JSON.parse(v.rights || '[]')
      }
      this.allVips = vipRights
      this.remainDiscountMoney = res.remainDiscountMoney
    },


    // 切换当前选中的套餐
    switchPackage(item) {
      if (item.vipRightId === this.currVip.id) {
        this.currPackage = item
      }
      this.reComputePrice()
    },


    reComputePrice() {
      const currPackage = this.currPackage
      const currVip = this.currVip
      const userVip = this.userVip
      const remainDiscountMoney = this.remainDiscountMoney || 0

      let price = currPackage.price || 0
      let finalPriceWithoutYanzhi = price
      let walletRemainMoney = this.wallet.totalRemainValue / 10

      const isSupportInviteCode = currPackage.isSupportInviteCode && (currPackage.vipRightId !== userVip.vipRightId || !currPackage.isSupportSameVipRightLevelUp)
      const isSupportYanzhi = currPackage.isSupportYanzhi && (currPackage.vipRightId !== userVip.vipRightId || !currPackage.isSupportSameVipRightLevelUp)

      if (currVip.isLevelUp || (currPackage.isSupportSameVipRightLevelUp && userVip.vipRightId === currVip.id)) {
        finalPriceWithoutYanzhi -= remainDiscountMoney
        finalPriceWithoutYanzhi = Math.max(0, finalPriceWithoutYanzhi)
      }

      if (isSupportInviteCode && this.form.useInviteCode && this.form.inviteCode.length === 6) {
        finalPriceWithoutYanzhi -= this.currVip.allowanceOnInviteCode || 0
        finalPriceWithoutYanzhi = Math.max(0, finalPriceWithoutYanzhi)
      }

      let finalPrice = finalPriceWithoutYanzhi
      if (isSupportYanzhi && this.form.useYanzhiPay) {
        finalPrice -= walletRemainMoney
        finalPrice = Math.max(finalPrice, 0)

        walletRemainMoney -= finalPriceWithoutYanzhi
        walletRemainMoney = Math.max(walletRemainMoney, 0)
      }

      this.computedPrice.price = price
      this.computedPrice.finalPriceWithoutYanzhi = finalPriceWithoutYanzhi
      this.computedPrice.finalPrice = finalPrice
      this.computedPrice.walletRemainYanzhi = walletRemainMoney * 10
    },


    async loadConfig() {
      const codes = Object.keys(this.config);
      const res = await configApiV2.findByCodes(codes);
      for (const row of res) {
        this.config[row.code] = row.value;
      }
    },

    async loadHasPay() {
      this.paySuccess = await clubV2Api.findMyHasPayClub();
    },

    copy() {
      copyToClipBoard('https://m.001ppt.com/club');
      this.$toast.success('复制成功')
    },

    // 初始化各种上偏移量
    async initTops() {
      await sleep(700);
      const headerHeight = this.$refs.header.clientHeight;
      const ids = ['right', 'comment', 'server'];
      for (const id of ids) {
        const pos = getElementPos(document.getElementById(id));
        this.tops[id] = pos.y - headerHeight;
      }
    },


    // 显示添加微信弹窗
    async showAddWxDialog() {
      try {
        if (this.paySuccess) {
          return this.$refs.clubPaySuccessDialog.show();
        }
        if (this.$route.query.from === 'huaban') {
          return this.$refs.clubAddWxDialog.show();
        }
        if (this.getBrowser() !== 'wechat') {
          return window.location.href = 'https://www.001ppt.com/urlScheme?remark=俱乐部落地页&path=pages/club/index';
        }
        this.$refs.clubAddWxDialog.show();
      } catch (e) {
        this.$refs.clubAddWxDialog.show();
      }
    },


    // 显示支付弹窗
    async startPay() {
      await this.loadHasPay();
      if (this.paySuccess) {
        return this.$refs.clubPaySuccessDialog.show();
      }
      if (this.getBrowser() !== 'wechat') {
        return this.$refs.clubH5PayDialog.show()
      }
      this.$refs.clubPayDialog.show();
    },

    // 滚动到对应位置
    scrollTo(id) {
      const headerHeight = this.$refs.header.clientHeight;
      const pos = getElementPos(document.getElementById(id));
      window.scrollTo({top: pos.y - headerHeight, behavior: 'smooth'});
    },

    // 滚动事件
    onScroll() {
      const introduce = document.querySelector('#introduce').getBoundingClientRect();
      const right = document.querySelector('#right').getBoundingClientRect();
      const comment = document.querySelector('#comment').getBoundingClientRect();
      const server = document.querySelector('#server').getBoundingClientRect();
      const headerHeight = this.$refs.header.clientHeight + 5

      if (server.top <= headerHeight) {
        this.scrollActive = 'server';
      } else if (comment.top <= headerHeight) {
        this.scrollActive = 'comment';
      } else if (right.top <= headerHeight) {
        this.scrollActive = 'right';
      } else {
        this.scrollActive = 'introduce';
      }
    },


    // 开启倒计时
    startCountDown() {
      const endTs = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1).getTime();
      const interval = setInterval(() => {
        const nowTs = Date.now();
        const delta = (endTs - nowTs);
        if (delta <= 0) {
          return clearInterval(interval);
        }
        const h = Math.floor(delta / 60 / 60 / 1000);
        const m = Math.floor((delta - h * 60 * 60 * 1000) / 60 / 1000);
        const s = Math.floor((delta - h * 60 * 60 * 1000 - m * 60 * 1000) / 1000);
        let ms = Math.floor(delta - h * 60 * 60 * 1000 - m * 60 * 1000 - s * 1000);
        if (ms < 10) {
          ms = '00' + ms
        } else if (ms < 100) {
          ms = '0' + ms
        }
        this.h = h;
        this.m = m;
        this.s = s;
        this.ms = ms;
      }, 50)
    },


    onPaySuccess() {
      this.paySuccess = true;
      return this.$refs.clubPaySuccessDialog.show();
    }


  }

}
</script>


------------------ styles ------------------
<style lang="less" scoped>
@club-header-height: 40px;
@club-footer-height: 50px;
@club-page-width: 100vw;

.header {
  background: #fffff4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: @club-header-height;
  padding: 0 16px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header-item {
  font-size: 14px;
  color: #151d36;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.header-item.active::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  border-radius: 100px;
  background: #151d36;
  bottom: -8px;
  width: 50%;
}

.body {
  background: #fffffd;

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: @club-header-height;
  }

  &::after {
    display: block;
    content: '';
    width: 100%;
    background: #fffff4;
    height: @club-footer-height;
  }
}

.club-img {
  width: 100vw;
  margin-bottom: -4px;
}

.rights-title {
  display: flex;
  align-items: center;

  .van-icon {
    transition: transform .3s;
  }

  &.rights-visible {
    .van-icon {
      transform: rotateZ(180deg);
    }
  }
}

.footer {
  position: fixed;
  height: @club-footer-height;
  width: @club-page-width;
  bottom: 0;
  background: #fff
}

.qa {
  background: #fffff4;
  font-size: 12px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  //height: 20px;
  color: #ee4a50;
  flex-direction: column;
  line-height: 20px;
}

.copy-btn {
  border-radius: 100px;
  border: 1px solid #ee4a50;
  padding: 2px 8px;
  color: #ee4a50;
  margin: 4px 0;
}

.link {
  //color: #2a82e4;
}

.add-wx-btn {
  position: relative;
  background: url("https://cdn.001ppt.cn/h5/img/club-pay-green.png");
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.add-wx-btn-main {
  font-size: 18px;
  font-weight: bolder;
  font-style: italic;
  color: #fff;
}

.add-wx-btn-sub {
  font-size: 12px;
  color: #f2f2f2;
  font-style: italic;
  margin-top: 4px;
}

.pay-btn {
  background: url("https://cdn.001ppt.cn/h5/img/club-pay-blue.png");
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.pay-btn-main {
  font-size: 16px;
  font-weight: bolder;
  font-style: italic;
  color: #fff;
}

.pay-btn-sub {
  font-size: 12px;
  color: #f2f2f2;
  font-style: italic;
  margin-top: 4px;
}


.countdown {
  position: absolute;
  right: 0;
  top: 0;
  background: url("https://cdn.001ppt.cn/h5/img/club-pay-gold.png");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 12px;
  padding: 0 8px;
  transform: scale(.6);
  transform-origin: 100% 0;
  color: #EE4A50;
}

.countdown-time {
  font-size: 20px;
  font-weight: bolder;
}


</style>
<style>
.club-page .van-popover__action, .club-page-popover-action {
  width: 160px !important;
}
</style>
